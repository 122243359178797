<template>
  <div>
    <MessagePage />
  </div>
</template>
<script>
import MessagePage from "@/components/message/MessagePage.vue";
export default {
  name: "messagePage",
  components: {
    MessagePage,
  },
};
</script>
